<template>
  <modal
    :title="$t('DEALS.DOCUMENTS.EDIT_DOCUMENT')"
    :close="close"
    title-border
  >
    <template #content>
      <form @submit.prevent="submit">
        <div class="px-6 py-5">
          <stip-dropdown-categories
            :placeholder="$t('DEALS.DOCUMENTS.DOCUMENT_TYPE')"
            :model-value="formValues.type || ''"
            name="type"
          />
        </div>

        <div class="border-t border-gray-200 flex justify-end py-5">
          <modal-buttons
            class="bg-white"
            :on-cancel="close"
            :on-save="handleSubmit"
            save-is-submit
          />
        </div>
      </form>
    </template>
  </modal>
</template>

<script lang="ts">
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import type { IFile } from "@/models/common";

interface Props {
  close: () => void;
  document: IFile;
}
</script>

<script setup lang="ts">
import Modal from "@/components/ui/Modal.vue";
import ModalButtons from "@/components/ui/ModalButtons.vue";
import StipDropdownCategories from "@/components/deals/StipDropdownCategories.vue";

const props = defineProps<Props>();

const { handleSubmit, values: formValues } = useForm({
  initialValues: {
    type: props.document.file_type
  }
});

const submit = handleSubmit(async (data, actions) => {
  const { id } = props.document;
  const payload = { id, data };
  await dispatchAction(payload, actions, "applications/updateFile");
  props.close();
});
</script>
